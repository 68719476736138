import { cloneDeep } from 'lodash-es';

export default angular
  .module('module.product', [])

  /* @ngInject */
  .directive('productDetail', function () {
    return {
      restrict: 'E',
      scope: false,
      bindToController: {
        id: '<idProduct',
        url: '@productUrl',
        idGroup: '<idProductGroup',
        urlGroup: '@productGroupUrl',
        isPrimary: '<',
        isKit: '<',
        showComponents: '<',
        allowAddToCart: '<',
        customizablePrice: '<',
        customizablePriceTooltip: '@',
        min: '<',
        step: '<',
        designation: '@productDesignation',
        img: '<productImg',
        modalCtrl: '<',
        model: '<',
        device: '<'
      },
      controllerAs: 'productCtrl',
      /* @ngInject */
      controller: function ($scope, $injector, $element) {
        const ctrl = this;
        ctrl.loaded = false;
        ctrl.attributes = [];
        ctrl.quantity = 1;
        ctrl.costTTC = 0;
        ctrl.costHT = 0;
        ctrl.customizationCostTTC = 0;
        ctrl.customizationCostHT = 0;
        ctrl.isMandatoryFieldsFilled = true;
        ctrl.nbFieldFilled = 0;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportProduct');
          service.initModule(ctrl, $scope, $element);
          if (ctrl.model.CompositionType === 12) {
            ctrl.Price = ctrl.model.Price;
            ctrl.minPrice = cloneDeep(ctrl.model.Price);
          }
          if (ctrl.model.Attributes.length > 0 && ctrl.model.Attributes[0].IDProductProperty === 69) {
            ctrl.attributes = ctrl.model.Attributes;
          }
          ctrl.isPristine = true;
        };

        $scope.$on('updateKitComponents', (event, components) => {
          ctrl.CustomizableKitComponents = components;
          ctrl.CustomizableKitComponents.forEach(component => {
            if (!component.Product) {
              ctrl.isMandatoryFieldsFilled = false;
            } else {
              component.isFilled = true;
            }
          });
        });

        $scope.$on('updateComponent', (event, component, prevProduct) => {
          ctrl.CustomizableKitComponents.forEach(c => {
            if (c.Id === component.Id) {
              if (component.Product) {
                c.isFilled = true;
              }
              // On recherche le produit avec le prix minimum
              const prices = [];
              c.Elements.forEach(item => {
                prices.push(item.Product.Price.HTPrice * item.Product.KitQuantity);
              });
              const minPrice = Math.min.apply(null, prices);
              let minPriceProduct = c.Elements.filter(item => item.Product.Price.HTPrice * item.Product.KitQuantity === minPrice)[0].Product;
              // Si le produit précédemment sélectionné n'est pas null et qu'il est différent du produit sélectionné par défaut, alors on met à jour le produit dont on soustrait le prix par le produit sélectionné
              if (prevProduct && prevProduct.IDProduct !== c.IDProductDefault && prevProduct.IDProduct !== component.Product.IDProduct) {
                minPriceProduct = prevProduct;
              }
              // On met à jour le composant sélectionné dans le groupement
              c.Product = component.Product;
              // On enlève le produit par défaut qui ne sert qu'à l'initialisation
              c.IDProductDefault = -1;
              if (ctrl.Price) {
                // On ajoute la différence de prix entre le minimum et le prix sélectionné au prix total du pack
                ctrl.Price.HTPrice += ((c.Product.Price.HTPrice * c.Product.KitQuantity) - (minPriceProduct.Price.HTPrice * minPriceProduct.KitQuantity));
                ctrl.Price.HTDiscountedPrice += ((c.Product.Price.HTDiscountedPrice * c.Product.KitQuantity) - (minPriceProduct.Price.HTDiscountedPrice * minPriceProduct.KitQuantity));
                ctrl.Price.TTCPrice += ((c.Product.Price.TTCPrice * c.Product.KitQuantity) - (minPriceProduct.Price.TTCPrice * minPriceProduct.KitQuantity));
                ctrl.Price.TTCDiscountedPrice += ((c.Product.Price.TTCDiscountedPrice * c.Product.KitQuantity) - (minPriceProduct.Price.TTCDiscountedPrice * minPriceProduct.KitQuantity));
              }
            }
          });
          ctrl.isMandatoryFieldsFilled = (ctrl.CustomizableKitComponents.every(item => item.isFilled));
          ctrl.addToCartActive = ctrl.isMandatoryFieldsFilled;
        });
      }
    };
  })

  /* @ngInject */
  .directive('productAdvice', function () {
    return {
      restrict: 'E',
      scope: false,
      controllerAs: 'productCtrl',
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
          $injector.loadNewModules([response.default.name]);
        };
      }
    };
  })

  /* @ngInject */
  .directive('productJobs', function () {
    return {
      restrict: 'E',
      scope: false,
      bindToController: {
        designation: '@',
        ref: '@',
        date: '@',
        ville: '@',
        department: '@'
      },
      controllerAs: 'productCtrl',
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
          $injector.loadNewModules([response.default.name]);
        };

        ctrl.linkForm = '/jobs?view=JobsForm';
      }
    };
  });
