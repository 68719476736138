import { merge, kebabCase } from 'lodash-es';
import 'Modules/Authentication/import/toast_authentication.tpl';

export default angular
  .module('directive.minilogin', [])

  /* @ngInject */
  .directive('miniLogin', function () {
    return {
      restrict: 'E',
      bindToController: {
        title: '@',
        titleVisitor: '@',
        titleLogin: '@',
        titleLogout: '@',
        device: '<'
      },
      controllerAs: 'miniLoginCtrl',
      /* @ngInject */
      controller: function ($scope, $rootScope, $element, $document, $location, $route, $timeout, toastr, AccountService, LogService, AppService) {
        const ctrl = this;

        ctrl.links = AccountService.getLinks();

        ctrl.$onInit = () => {
          ctrl.clientIsActive = ctrl.device.mobile;
          ctrl.visitor = ctrl.titleVisitor;
          update();
        };

        const $body = $(document.body);
        $body.addClass('offcanvas');
        const $header = $('#header');

        ctrl.toggle = () => {
          toggle();
        };

        ctrl.login = event => {
          if (!ctrl.isInitialized) {
            $rootScope.$broadcast('showPageLoader', true);
            return;
          }
          if (ctrl.isLogged && ctrl.device.mobile) {
            toggle();
            return;
          }
          if (ctrl.isLogged) return;
        };

        ctrl.logout = () => {
          $timeout(() => {
            ctrl.visitor = ctrl.title;
            ctrl.isLogged = false;
          });
          toggle();
          $rootScope.$broadcast('showPageLoader', true);
          LogService.logout()
            .then(response => {
              if (response.status === 'OK') {
                AppService.updateParams(merge(response.VisitorContext, { IsLogged: false }));
                $rootScope.$broadcast('logUpdate', false);
                update();

                toastr.success('', ctrl.titleLogout, {
                  allowHtml: true,
                  extraData: {
                    template: 'Modules/Authentication/import/toast_authentication.tpl'
                  }
                });

                if ($route.current && $route.current.resolve && $route.current.resolve.authorize) {
                  $location.path('/');
                }
              }
              window.location.href = '/';
              $rootScope.$broadcast('showPageLoader', false);
            })
            .catch(error => {
              console.error(error);
            });
        };

        // Functions

        $scope.$on('userUpdate', () => {
          update(true);
        });

        $scope.$on('logUpdate', (event, value) => {
          if (value) {
            toastr.success('', ctrl.titleLogin, {
              allowHtml: true,
              extraData: {
                template: 'Modules/Authentication/import/toast_authentication.tpl'
              }
            });
          }
          update();
        });

        $scope.$on('logout', () => {
          ctrl.logout();
        });

        // Functions

        function toggle() {
          if ($body.hasClass('offcanvas-right')) {
            $body.removeClass('offcanvas-right');
            $document.off('click', onBodyClick);
          } else {
            $('.navmenu-fixed-right').css({ top: $header.position().top + $header.height() - $(window).scrollTop() });
            $body.addClass('offcanvas-right');
            $document.on('click', onBodyClick);
          }
        }

        function onBodyClick(event) {
          if (!$element[0].contains(event.target)) {
            toggle();
          }
        }

        function update(refresh) {
          AppService.getParams(refresh)
            .then(params => {
              ctrl.isLogged = params.IsLogged;
              ctrl.sellerUrl = null;
              if (ctrl.isLogged) {
                ctrl.visitor = params.Visitor.FullName;
                if (params.Visitor.CardType.KeyCardType === 'PERSONNEL') {
                  ctrl.sellerUrl = `/${kebabCase(params.Visitor.FirstName + params.Visitor.LastName.slice(0, 1))}-v-${params.Visitor.IDCard}`;
                }
              }
              AccountService.setManagement(ctrl);
              if (refresh) {
                $rootScope.$broadcast('cartUpdate');
              }
              !$rootScope.$$phase && $scope.$digest();
              ctrl.isInitialized = true;
              $rootScope.$broadcast('showPageLoader', false);
            })
            .catch(error => {
              console.error(error);
            });
        }
      }
    };
  });
