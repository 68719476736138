export default angular
  .module('finder.service.parse', [])

  /* @ngInject */
  .factory('FinderParseService', function ($rootScope, $filter, AppService, PriceService, AvailabilityService) {
    const service = {
      parse
    };
    return service;

    ////////////
    async function parse(search, items) {
      return Promise.all(
        items.map(async item => {
          const obj = {
            designationHtml: $filter('matchstring')(item.Designation, search)
          };

          if (item.Availability) {
            obj.Availability = item.Availability;
            const { Site } = await AppService.getParams();
            if (Site.DisplayAvailabilityOnListPage && Site.IsOmnichannelOrder) {
              await AvailabilityService.formatMessage(obj.Availability.Detail, 'Delivery');
              await AvailabilityService.formatMessage(obj.Availability.Detail, 'PickUp');
              obj.Availability.Detail.modalData = {
                idProduct: item.IDProduct,
                action: $rootScope.forceReload
              };
            }
          }

          obj.htmlPrice = await PriceService.get(item);

          /*
          obj.Attributes = item.Attributes;
          obj.Attributes.forEach(att => {
            att.designationKey = kebabCase(att.Designation);
          });
          const attrs = ['taille', 'couleur', 'coloris', 'modele', 'forme', 'parfum', 'caracteristique', 'contenance'];
          obj.hasValidAttributes = item.Attributes.filter(o => o.Values.length > 1 && attrs.includes(o.designationKey)).length > 0;
          */

          return {
            IDProduct: item.IDProduct,
            Designation: item.Designation,
            URL: item.URL,
            IDPicture: item.MainPicture ? item.MainPicture.IDPicture : item.IDPicture,
            Price: item.Price,
            Themes: item.Themes.filter(theme => theme.KeyTheme === 'Nouveaute' || theme.KeyTheme === 'Promotion'),
            ...obj
          };
        })
      );
    }
  });
