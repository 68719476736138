angular
  .module('account-check.directive', [])
  /* Utilisation de https://regexlib.com/ et https://rgxdb.com/ */
  /* @ngInject */
  .directive('accountPhone', function ($timeout, $parse) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        const regexFrenchPhone = /^(?:\+33|0033|\(\+33\)|0)(?:-| |)([1|2|3|4|5|6|7|9]{1})(?:-| |)([0-9]{2})(?:-| |)([0-9]{2})(?:-| |)([0-9]{2})(?:-| |)([0-9]{2})$/;
        const regexUniversalPhone = /^\+?([0-9])([0-9().●\- ]{5,15})$/;
        const frCountryCode = 'FR';
        let regexPhone = regexFrenchPhone;
        const countries = scope.$eval(attrs.countriesPhone);

        const watcher = scope.$watch(attrs.accountPhone, function (value) {
          if (!value) {
            return;
          }

          //Selon l'endroit où on appelle la directive, on s'adapte car les données sont différentes
          const countryCode = value.CountryCode ? value.CountryCode : countries.find(c => c.IDCountry.toString() === value).CountryCode;

          regexPhone = countryCode === frCountryCode ? regexFrenchPhone : regexUniversalPhone;
          refreshField();

          if (attrs.ngModel) {
            const val = modelCtrl.$viewValue;
            $parse(attrs.ngModel).assign(scope, val);
          }
        });

        scope.$on('$destroy', function () {
          watcher();
        });

        modelCtrl.$validators.phone = phoneValidator;

        function phoneValidator(modelValue) {
          if (!modelValue) {
            return true;
          }

          return regexPhone.test(modelValue);
        }

        function refreshField() {
          modelCtrl.$setValidity('phone', true);
          $timeout(function () {
            scope.$apply(function () {
              modelCtrl.$validate();
            });
          });
        }
      }
    };
  });
