import { deburr, kebabCase } from 'lodash-es';

export default angular
  .module('module.cdn', [])

  /* @ngInject */
  .factory('CdnService', function (AppService) {
    return {
      get: async (id, size, designation, extension, quality, absolute, crop, transparent) => {
        if (angular.isUndefined(size)) {
          size = '1200x1200';
        }
        if (angular.isUndefined(designation)) {
          designation = 'Image';
        }
        if (angular.isUndefined(extension)) {
          extension = 'jpg';
        }
        if (angular.isUndefined(quality)) {
          quality = 80;
        }
        if (angular.isUndefined(absolute)) {
          absolute = false;
        }

        const params = await AppService.getParams();
        const split1 = params.Localization.UrlImages.substr(0, params.Localization.UrlImages.indexOf('.'));
        const split2 = params.Localization.UrlImages.substr(params.Localization.UrlImages.indexOf('.') + 1);
        let url = split1 === '' ? split2 : `${split1 + (id % 5)}.${split2}`;

        if (absolute && url.substr(0, 6) === '/Image') {
          url = params.Localization.URLSite.substr(0, params.Localization.URLSite.length - 1) + url;
        }

        if (quality <= 0) {
          quality = 1;
        }
        if (quality > 100) {
          quality = 100;
        }

        if (designation === '') {
          designation = `${params.Localization.TradeName} ${id}`;
        }

        const queryParams = [];
        if (quality !== 80) {
          queryParams.push(`quality=${quality}`);
        }

        if (crop) {
          queryParams.push('crop');
        }

        if (transparent) {
          queryParams.push('transparent');
        }

        url = `${url}/${id}/${size}/${kebabCase(deburr(designation))}.${extension}`;

        if (queryParams.length) {
          url += `?${queryParams.join('&')}`;
        }

        return url;
      }
    };
  })

  /* @ngInject */
  .directive('cdn', function (CdnService) {
    return {
      restrict: 'A',
      priority: 100,
      scope: {
        cdnId: '@?',
        cdnSize: '@?',
        cdnDesignation: '@?',
        cdnExtension: '@?',
        cdnQuality: '@?',
        cdnAbsolute: '@?',
        cdnCrop: '@?',
        cdnTransparent: '@?',
        cdnChangeable: '@?'
      },
      link: (scope, element, attrs) => {
        if (scope.cdnId === '0' && attrs.distrimageSrc && attrs.distrimageSrc !== '') return;

        if (angular.isDefined(attrs.cdnChangeable)) {
          const observerCdnId = attrs.$observe('cdnId', () => {
            processImage();
          });
          scope.$on('$destroy', observerCdnId);
        } else {
          processImage();
        }

        async function processImage() {
          const res = await CdnService.get(
            scope.cdnId,
            scope.cdnSize,
            scope.cdnDesignation,
            scope.cdnExtension,
            scope.cdnQuality,
            scope.cdnAbsolute,
            scope.cdnCrop,
            scope.cdnTransparent
          );
          setSrc(element, attrs, res);
        }

        function setSrc(_element, _attrs, res) {
          if (_element[0].tagName.toLowerCase() === 'img') {
            if (angular.isDefined(_attrs.lazy) && !_element.hasClass('loaded')) {
              _attrs.$set('lazy', res);
            } else if (angular.isDefined(_element.data('src')) && !_element.hasClass('loaded') && !_element.hasClass('owl-lazy')) {
              _element.attr('data-src', res);
            } else {
              _element.attr('src', res);

              if (_element.hasClass('owl-lazy')) {
                _element.attr('style', 'opacity: 1');
              }
            }
          } else {
            if (angular.isDefined(_element.data('srcset')) && !_element.parent().children('img').hasClass('loaded') && !_element.parent().children('img').hasClass('owl-lazy')) {
              _element.attr('data-srcset', res);
            } else {
              _element.attr('srcset', res);
            }
          }
        }
      }
    };
  });
