/* eslint-disable */
(function () {
  'use strict';
  if (typeof window.isBot != 'undefined') {
    throw new Error("");
  }
  if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  }
  var pattern = new RegExp('bot|crawl|yahoo|facebook|google', 'i');
  window.isBot = !('onscroll' in window) || pattern.test(navigator.userAgent);
  window.isCache = window.location.origin.indexOf('webcache.googleusercontent') !== -1 || window.location.origin.indexOf('bingj.com') !== -1;
})();
