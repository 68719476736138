export default angular
  .module('directive.top.promos', [])

  /* @ngInject */
  .directive('topPromos', function () {
    return {
      restrict: 'E',
      scope: true,
      bindToController: {
        count: '<'
      },
      controllerAs: 'ctrl',
      /* @ngInject */
      controller: function ($element, $rootScope, $timeout, $interval) {
        const ctrl = this;
        let interval;

        ctrl.$onInit = () => {
          if (ctrl.count === 0) return;

          ctrl.index = 0;

          ctrl.active = 1;

          $timeout(() => {
            $timeout(
              () => {
                $rootScope.$broadcast('topUpdate');
              },
              400,
              false
            );
            if (ctrl.count > 1) startAnimate();
            resize();
          }, 0);
        };

        // Functions
        function startAnimate() {
          interval = $interval(() => {
            ctrl.index = ctrl.index === ctrl.count - 1 ? 0 : ctrl.index + 1;
          }, 5000);

          ctrl.$onDestroy = () => {
            stopAnimate();
          };
        }
        function stopAnimate() {
          $interval.cancel(interval);
        }

        function resize() {
          $timeout(
            () => {
              let h = 0;
              $element
                .find('.inner')
                .children('.item')
                .each(function () {
                  h = Math.max(h, $(this).outerHeight());
                });
              $element.find('.inner').height(h);
            },
            0,
            false
          );
        }
      }
    };
  });
