export default angular
  .module('directive.price', [])

  .directive('productPrice', function () {
    return {
      restrict: 'E',
      require: {
        productCtrl: '^^productDetail'
      },
      bindToController: {
        jsonPrice: '=',
        prices: '<',
        txtFirstRange: '@',
        txtRange: '@',
        txtLastRange: '@',
        txtConditioning: '@?'
      },
      /* @ngInject */
      controller: function ($element, $injector, $timeout) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportProduct');
          service.initPrice(ctrl);
          resizeWidth();
        };

        $(window).resize(function () {
          resizeWidth();
        });

        function resizeWidth() {
          $timeout(() => {
            const table = $element.find('.degressive-price').find('.d-table').toArray();
            let maxWidthLeft = 0;
            let maxWidthRight = 0;
            table.forEach(item => {
              const left = $(item).find('.cell-left');
              const right = $(item).find('.cell-right').find('span');
              if (left.width() > maxWidthLeft) {
                maxWidthLeft = left.width();
              }
              if (right.width() > maxWidthRight) {
                maxWidthRight = right.width();
              }
            });
            table.forEach(item => {
              $(item).find('.cell-left').attr('style', `width: ${maxWidthLeft + 5}px`);
              $(item).find('.cell-right').attr('style', `width: ${maxWidthRight + 12.5}px`);
            });
          });
        }
      }
    };
  });
