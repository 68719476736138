import { remove } from 'lodash-es';

export default angular
  .module('section.form', [])

  .directive('sectionForm', function () {
    return {
      restrict: 'A',
      bindToController: {
        sectionForm: '@'
      },
      controllerAs: 'formCtrl',
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          onInit();
          const response = await import(/* webpackChunkName: "form" */ 'Modules/Form/import/form.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportForm');
          service[`init${ctrl.sectionForm}`](ctrl, $scope);
        };

        ctrl.formData = {
          errors: []
        };

        ctrl.init = object => {
          ctrl.formData.IsDevis = object.isDevis;
          // TODO ctrl.formData.CustomerPro = object.CustomerPro === 'True' ? true : false;
        };

        function onInit() {
          if (ctrl.sectionForm === 'Contact') {
            ctrl.dzOptions = {
              paramName: 'files',
              acceptedFiles: 'image/jpeg, image/jpg, image/png, image/gif, .pdf, .doc, .docx, .xls, .xlsx, .zip',
              uploadMultiple: true,
              renameFilename(name) {
                return `${new Date().getTime()}_!_${name}`;
              }
            };

            ctrl.dzCallbacks = {
              successmultiple(files) {
                files.forEach(file => {
                  ctrl.formData.Attachments.push({
                    Name: file.name,
                    ServerName: file.serverName
                  });
                });
              },
              removedfile(file) {
                remove(ctrl.formData.Attachments, { Name: file.name });
              }
            };
          }
        }
      }
    };
  });
