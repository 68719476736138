import format from 'import/js/plugins/format.js';

export default angular
  .module('filter.price', [])

  /* @ngInject */
  .filter('price', function ($rootScope, AppService) {
    let params = null;

    function priceFilter(prices, str, ttc) {
      if (prices === null || angular.isUndefined(prices) || (!angular.isObject(prices) && isNaN(prices))) return prices;
      ttc = ttc === 'ht' ? false : true;

      if (params !== null) {
        return !angular.isFunction(params.then) ? getPriceFilter(prices, str, ttc) : undefined;
      }
      params = AppService.getParams()
        .then(data => {
          params = data;
        })
        .catch(error => {
          console.error(error);
        });
    }

    /**
     * Return calculated conditionning total price for a product
     * @param {Object} item product with at least prices and quantity fields
     */
    function getConditioningPrice(item) {
      let quantity = item.quantity;

      const prices = item.prices;
      prices.sort((a, b) => b.qty - a.qty);

      return prices.reduce((result, price) => {
        if (quantity === 0) {
          return result;
        }

        const remainder = quantity % price.qty;
        const priceValue = params.Visitor.CardType.ShowTTCPrice ? price.TTCDiscountedPrice : price.HTDiscountedPrice;

        result += (quantity - remainder) * priceValue;
        quantity = remainder;

        return result;
      }, 0);
    }

    function getPriceFilter(prices, str, ttc) {
      if (!angular.isFunction(format)) return prices;

      const loc = params.Localization;
      let value;
      let template = params.Visitor.CardType.ShowTTCPrice ? loc.PriceHTMLFormatVATincluded : loc.PriceHTMLFormatVATexcluded;
      switch (str) {
        case 'value':
        case 'round-value':
          value = prices;
          template = ttc ? loc.PriceHTMLFormatVATincluded : loc.PriceHTMLFormatVATexcluded;
          break;
        case 'amount-price':
          value = params.Visitor.CardType.ShowTTCPrice ? prices.TTCAmount : prices.HTAmount;
          break;
        case 'final-price':
          value = params.Visitor.CardType.ShowTTCPrice ? prices.TTCDiscountedPrice : prices.HTDiscountedPrice;
          break;
        case 'total-price':
          value = params.Visitor.CardType.ShowTTCPrice ? prices.TTCTotalPrice : prices.HTTotalPrice;
          break;
        case 'eco':
          value = params.Visitor.CardType.ShowTTCPrice ? prices.EcoContribution.TTCAmount : prices.EcoContribution.HTAmount;
          break;
        case 'conditioning-price':
          value = getConditioningPrice(prices);
          break;
        default:
          value = params.Visitor.CardType.ShowTTCPrice ? prices.TTCPrice : prices.HTPrice;
      }
      if (angular.isUndefined(value)) return;

      const strMontant = format(loc.PriceFormat, parseFloat(Math.round(parseFloat(value) * 100) / 100));
      const separatorIndex = strMontant.indexOf('.');

      if (str === 'round-value' && Math.floor(value) === value) {
        template = template.replace('[%decimal-separator%]', '');
        template = template.replace('[%amount-dec%]', '');
      }

      const render = template
        .replace('[%amount%]', strMontant)
        .replace(',', '##PriceTSep##')
        .replace('[%amount-int%]', separatorIndex !== -1 ? strMontant.substring(0, separatorIndex) : strMontant)
        .replace('[%amount-dec%]', separatorIndex !== -1 ? strMontant.substring(separatorIndex + 1) : '')
        .replace('[%decimal-separator%]', loc.PriceDecimalSeparator)
        .replace('.', loc.PriceDecimalSeparator)
        .replace('##PriceTSep##', loc.PriceThousandSeparator)
        .replace('[%currency%]', params.Currency.Symbol)
        .replace('[%currencyCode%]', params.Currency.ISOCode);

      return render;
    }

    $rootScope.$on('logUpdate', () => {
      params = null;
    });

    priceFilter.$stateful = true;
    return priceFilter;
  });
