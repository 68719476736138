export default angular
  .module('controller.modal', [])

  /* @ngInject */
  .controller('modalCtrl', function (data, device, options, $scope, smoothScroll, HttpService, ModalService, $window) {
    const ctrl = this;
    ctrl.data = data;
    ctrl.device = device;
    ctrl.options = options;
    ctrl.showLoader = true;
    $scope.device = device;

    ctrl.onLoad = () => {
      ctrl.showLoader = false;
    };

    ctrl.deleteQuantity = function (dataQtd) {
      ctrl.data = dataQtd;
      HttpService.post({
        url: `/UpdateLineQuantityCart/${ctrl.data.IDLine}/${ctrl.data.RemovedQuantity}`
      }).then(function (response) {
        if (response.status === 'OK') {
          ModalService.close();
          $window.location.reload();
        }
      });
    };

    ctrl.action = value => {
      if (!ctrl.data.isBtnMainDisabled) {
        ctrl.$close();
        if (ctrl.data.action) {
          ctrl.data.action(value);
        } else {
          console.log('no action available');
        }
      }
    };

    ctrl.cancel = value => {
      ctrl.$close();
    };

    ctrl.goTo = key => {
      smoothScroll(document.getElementById(key), { offset: 70, containerId: 'scrollContainer' });
    };
  })

  /* @ngInject */
  .controller('modalAddToCartCtrl', function (data, device, options, $scope) {
    const ctrl = this;
    ctrl.data = data;
    ctrl.device = device;
    ctrl.options = options;
    $scope.device = device;

    if (ctrl.data.length === 1) {
      ctrl.suggestionsUrl = `GetLinkedProducts/Modal/ModalAddToCartLinkedProducts/${ctrl.data[0].idProduct}?${new Date().getTime()}`;
    }
  })

  /* @ngInject */
  .controller('modalSendByEMailCtrl', function (data, device, options, HttpService, AppService, ModalService, toastr) {
    const ctrl = this;

    ctrl.data = data;
    ctrl.data.Message = '';
    ctrl.data.Recipients = [{}];
    ctrl.modalTitle = options.modalTitle;

    ctrl.addRecipient = () => {
      ctrl.data.Recipients.push({});
    };
    ctrl.removeRecipient = index => {
      ctrl.data.Recipients.splice(index, 1);
    };

    AppService.getParams()
      .then(params => {
        if (params.IsLogged) {
          ctrl.data.Email = params.Visitor.Email;
          ctrl.data.FirstName = params.Visitor.FirstName;
          ctrl.data.LastName = params.Visitor.LastName;
        }
      })
      .catch(error => {
        console.error(error);
      });

    ctrl.submit = () => {
      ctrl.loading = true;

      HttpService.post({
        url: '/SendByEmail',
        data: ctrl.data
      })
        .then(response => {
          AppService.getTranslate()
            .then(translate => {
              if (response.status === 'OK') {
                ModalService.close();
                toastr.success('', options.resultMessage, {
                  allowHtml: true,
                  extraData: {
                    template: 'import/js/libs/angular-toastr/toast_message.tpl'
                  }
                });
              } else {
                toastr.warning(translate.errors.TryLater, translate.errors.ErrorHasOccurred, {
                  allowHtml: true,
                  extraData: {
                    template: 'import/js/libs/angular-toastr/toast_message.tpl'
                  }
                });
              }
              ctrl.loading = false;
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          console.error(error);
        });
    };
  })

  /* @ngInject */
  .controller('modalAvailabilityAlertCtrl', function (data, device, options, ModalService, AppService, HttpService, toastr) {
    const ctrl = this;
    ctrl.data = data;
    ctrl.data.Email = '';

    let messageTitle, messageText;

    ctrl.successMessage = (title, text) => {
      messageTitle = title;
      messageText = text;
    };

    ctrl.submit = () => {
      ctrl.loading = true;
      HttpService.post({
        url: '/Product/AvailabilityAlert',
        data: ctrl.data
      })
        .then(response => {
          ctrl.loading = false;
          if (response.status === 'OK') {
            ModalService.close();
            toastr.success(messageText, messageTitle, {
              allowHtml: true,
              extraData: {
                template: 'import/js/libs/angular-toastr/toast_message.tpl'
              }
            });
          } else {
            AppService.getTranslate()
              .then(translate => {
                toastr.warning(translate.errors.TryLater, translate.errors.ErrorHasOccurred, {
                  allowHtml: true,
                  extraData: {
                    template: 'import/js/libs/angular-toastr/toast_message.tpl'
                  }
                });
              })
              .catch(error => {
                console.error(error);
              });
          }
        })
        .catch(error => {
          console.error(error);
        });
    };
  })

  /* @ngInject */
  .controller('modalQuestionCtrl', function (data, device, options, HttpService, AppService, ModalService, toastr) {
    const ctrl = this;
    ctrl.data = data;
    let messageTitle;

    ctrl.successMessage = title => {
      messageTitle = title;
    };

    ctrl.emailChanged = () => {
      if (ctrl.form.Email.$valid) {
        try {
          AppService.emailExists({
            email: ctrl.data.Email,
            openModal: true,
            action() {
              ModalService.show(
                '/Template/Product/ModalQuestion',
                {
                  IdProduct: ctrl.data.IdProduct
                },
                null,
                'modalQuestionCtrl'
              );
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
    };

    ctrl.submit = async () => {
      ctrl.loading = true;
      try {
        const { status } = await HttpService.post({
          url: ctrl.IsCrmV2 ? '/Message/QuestionProduct' : '/Product/QuestionProduct',
          data: ctrl.data
        });
        if (status === 'OK') {
          ModalService.close();
          toastr.success('', messageTitle, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        } else {
          const { errors } = await AppService.getTranslate();
          toastr.warning(errors.TryLater, errors.ErrorHasOccurred, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
      ctrl.loading = false;
    };

    onInit();
    // Functions
    async function onInit() {
      const params = await AppService.getParams();

      ctrl.IsCrmV2 = params.Site.IsCrmV2;
      if (params.IsLogged) {
        ctrl.data.Email = params.Visitor.Email;
        ctrl.data.FirstName = params.Visitor.FirstName;
        ctrl.data.LastName = params.Visitor.LastName;
      }
    }
  })

  /* @ngInject */
  .controller('modalQuickViewCtrl', function (data, device, options, $scope) {
    const ctrl = this;
    ctrl.data = data;
    ctrl.device = device;
    ctrl.options = options;
    ctrl.showLoader = true;
    $scope.device = device;

    ctrl.onLoad = () => {
      ctrl.showLoader = false;
      setTimeout(() => {
        $('.modal-quick-view .modal-body').removeAttr('style');
      });
    };
  })

  /* @ngInject */
  .controller('uploadCropModalCtrl', function (data, device, options, $scope, ModalService, $uibModalInstance) {
    const ctrl = this;
    ctrl.data = data;
    ctrl.device = device;
    ctrl.options = options;
    ctrl.showLoader = true;
    $scope.device = device;

    let cropper;

    $uibModalInstance.rendered.then(async () => {
      await init();
    });

    ctrl.plus = () => {
      cropper.zoom(0.1);
    };
    ctrl.minus = () => {
      cropper.zoom(-0.1);
    };
    ctrl.rotate = () => {
      cropper.rotate(90);
    };

    ctrl.save = () => {
      const dropzone = ctrl.data.ctrlFrom.dropzone;
      dropzone.options.url = '/Upload';
      const blob = cropper
        .getCroppedCanvas({
          width: 240,
          height: 240,
          fillColor: '#fff',
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high'
        })
        .toDataURL('image/jpeg');
      const croppedFile = dataURItoBlob(blob);
      croppedFile.name = ctrl.data.name;

      ctrl.data.ctrlFrom.tmpFileName = ctrl.data.name;
      dropzone.removeAllFiles();
      dropzone.addFile(croppedFile);

      ctrl.data.ctrlFrom.deleteTmpFile(ctrl.data.serverName);
      ModalService.close();
    };

    async function init() {
      await import(/* webpackChunkName: "cropper" */ 'import/js/directives/dropzone/cropper.less');
      const response = await import(/* webpackChunkName: "cropperjs" */ 'cropperjs');
      ctrl.showLoader = false;
      // eslint-disable-next-line
      cropper = new response.default(document.getElementById('img_' + ctrl.data.tmpId), {
        aspectRatio: 1,
        movable: true,
        cropBoxResizable: true,
        rotatable: true,
        ready: function () {
          // eslint-disable-next-line
          $(this.cropper.cropper).addClass('cropper-circle');
        }
      });
    }

    /*eslint-disable*/
    function dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab),
        i;
      for (i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: 'image/jpeg' });
    }
    /*eslint-enable*/
  });
