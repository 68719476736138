import { kebabCase } from 'lodash-es';

const routes = {};
let routingService;

export default angular
  .module('app.routing', [])

  /* @ngInject */
  .config(function ($routeProvider, $locationProvider, $httpProvider, Routes) {
    $httpProvider.useApplyAsync(true);
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'userHttpRequest';
    $httpProvider.interceptors.push('HttpRequestInterceptor');

    const when = (route, config) => {
      let ngRoute;
      if (route) {
        ngRoute = `/${route[_LNG_].URL.replace(/\:(\S+?)\}/g, '}').replace(/\{(\S+?)\}/g, ':$1')}`;
        routes[route.Name] = { url: route[_LNG_].URL, route: ngRoute };
      }
      // désactivation du routing pour Google
      if (window.isBot || window.isCache || !config) return;
      $routeProvider.when(ngRoute, config);
    };

    Routes.forEach(route => {
      if (route.Controller.indexOf('Account') === 0) {
        // Compte
        const component = kebabCase(route.Name);
        const resolve = {
          /* @ngInject */
          authorize: authService => authService.authorize(),
          /* @ngInject */
          load: ($q, $injector) => {
            const deferred = $q.defer();
            import(/* webpackChunkName: "account.module" */ 'Modules/Account/import/module.js').then(response => {
              $injector.loadNewModules([response.default.name]);
              deferred.resolve();
            });
            return deferred.promise;
          }
        };
        let bindings = '';
        if (route.Name === 'AccountReturns') {
          /* @ngInject */
          resolve.result = AccountService => AccountService.getReturnFolders();
          bindings = ' result="$resolve.result"';
        } else if (route.Name === 'AccountUpdate') {
          /* @ngInject */
          resolve.result = AccountService => AccountService.getShippingAddresses();
          bindings = ' result="$resolve.result"';
        }
        when(route, {
          template: `<${component}${bindings} device="device"></'${component}>`,
          resolve,
          name: route.Controller,
          ngRoute: true
        });
      } else if (route.Controller.indexOf('Cart') !== -1) {
        // Panier
        if (route.Name === 'QuickOrder') {
          when(route, {
            templateUrl: '/Template/Cart/QuickOrderView',
            name: route.Name,
            cart: true,
            resolve: {
              /* @ngInject */
              load: ($q, $injector) => {
                const isOnePage = $(document.body).data('cart') === true;
                const deferred = $q.defer();
                import(/* webpackChunkName: "cart.quick.order" */ 'Modules/Cart/import/quick.order.js').then(response => {
                  $injector.loadNewModules([response.default.name]);
                  if (isOnePage) {
                    import(/* webpackChunkName: "cart.onepage.display" */ 'Modules/Cart/import/onepage/display.js').then(_response => {
                      $injector.loadNewModules([_response.default.name]);
                      deferred.resolve();
                    });
                  } else {
                    import(/* webpackChunkName: "cart.steps.display" */ 'Modules/Cart/import/steps/display.js').then(_response => {
                      $injector.loadNewModules([_response.default.name]);
                      deferred.resolve();
                    });
                  }
                });
                return deferred.promise;
              }
            },
            ngRoute: true
          });
        } else if (route.Name === 'Cart' || route.Name === 'CartRecognition') {
          when(route, {
            templateUrl: () => {
              const isOnePage = $(document.body).data('cart') === true;
              return `/Template/Cart/${isOnePage ? 'OnePageCheckout' : 'StepsCheckout'}/Display`;
            },
            name: route.Name,
            cart: true,
            resolve: {
              /* @ngInject */
              load: ($q, $injector) => {
                const isOnePage = $(document.body).data('cart') === true;
                const deferred = $q.defer();
                if (isOnePage) {
                  import(/* webpackChunkName: "cart.onepage.display" */ 'Modules/Cart/import/onepage/display.js').then(response => {
                    $injector.loadNewModules([response.default.name]);
                    deferred.resolve();
                  });
                } else {
                  import(/* webpackChunkName: "cart.steps.display" */ 'Modules/Cart/import/steps/display.js').then(response => {
                    $injector.loadNewModules([response.default.name]);
                    deferred.resolve();
                  });
                }
                return deferred.promise;
              }
            },
            ngRoute: true
          });
        } else if (route.Name === 'CartIdentification') {
          when(route, {
            templateUrl: `/Template/Cart/${route.Name.split('Cart')[1]}`,
            name: route.Name,
            cart: true,
            resolve: {
              /* @ngInject */
              load: ($q, $injector) => {
                const isOnePage = $(document.body).data('cart') === true;
                const deferred = $q.defer();
                if (isOnePage) {
                  import(/* webpackChunkName: "cart.onepage.identification" */ 'Modules/Cart/import/onepage/identification.js').then(response => {
                    $injector.loadNewModules([response.default.name]);
                    deferred.resolve();
                  });
                } else {
                  import(/* webpackChunkName: "cart.steps.identification" */ 'Modules/Cart/import/steps/identification.js').then(response => {
                    $injector.loadNewModules([response.default.name]);
                    deferred.resolve();
                  });
                }
                return deferred.promise;
              }
            },
            ngRoute: true
          });
        } else if (route.Name === 'CartOnePageCheckout') {
          when(route, {
            templateUrl: () => `/Template/Cart/OnePageCheckout/${route.Name.split('Cart')[1]}?t${routingService.getCacheBusting()}`,
            name: route.Name,
            cart: true,
            resolve: {
              /* @ngInject */
              authorize: authService => authService.authorize(true),
              /* @ngInject */
              load: ($q, $injector, $templateCache) => {
                const deferred = $q.defer();
                import('Modules/Cart/import/onepage/gmap.html').then(html => {
                  $templateCache.put('Modules/Cart/import/onepage/gmap.html', html.default);
                  import(/* webpackChunkName: "cart.onepage" */ 'Modules/Cart/import/onepage/onePageCkeckout.js').then(response => {
                    $injector.loadNewModules([response.default.name]);
                    deferred.resolve();
                  });
                });
                return deferred.promise;
              }
            },
            authorize: true,
            ngRoute: true
          });
        } else if (route.Name === 'CartOnePageCheckoutPayment') {
          when(route, {
            templateUrl: '/Template/Cart/OnePageCheckout/OnePageCheckout?payment',
            name: route.Name,
            cart: true,
            resolve: {
              /* @ngInject */
              authorize: authService => authService.authorize(true),
              /* @ngInject */
              load: ($q, $injector) => {
                const deferred = $q.defer();
                import(/* webpackChunkName: "cart.onepage" */ 'Modules/Cart/import/onepage/onePageCkeckout.js').then(response => {
                  $injector.loadNewModules([response.default.name]);
                  deferred.resolve();
                });
                return deferred.promise;
              }
            },
            authorize: true,
            ngRoute: true
          });
        } else if (route.Name === 'CartShipping') {
          when(route, {
            templateUrl: `/Template/Cart/StepsCheckout/${route.Name.split('Cart')[1]}`,
            name: route.Name,
            cart: true,
            resolve: {
              /* @ngInject */
              authorize: authService => authService.authorize(true),
              /* @ngInject */
              load: ($q, $injector, $templateCache) => {
                const deferred = $q.defer();
                import('Modules/Cart/import/steps/gmap.html').then(html => {
                  $templateCache.put('Modules/Cart/import/steps/gmap.html', html.default);
                  import(/* webpackChunkName: "cart.steps.shipping" */ 'Modules/Cart/import/steps/shipping.js').then(response => {
                    $injector.loadNewModules([response.default.name]);
                    deferred.resolve();
                  });
                });
                return deferred.promise;
              }
            },
            authorize: true,
            ngRoute: true
          });
        } else if (route.Name === 'CartPayment') {
          when(route, {
            templateUrl: `/Template/Cart/StepsCheckout/${route.Name.split('Cart')[1]}`,
            name: route.Name,
            cart: true,
            resolve: {
              /* @ngInject */
              authorize: authService => authService.authorize(true),
              /* @ngInject */
              load: ($q, $injector) => {
                const deferred = $q.defer();
                import(/* webpackChunkName: "cart.steps.payment" */ 'Modules/Cart/import/steps/payment.js').then(response => {
                  $injector.loadNewModules([response.default.name]);
                  deferred.resolve();
                });
                return deferred.promise;
              }
            },
            authorize: true,
            ngRoute: true
          });
        } else if (route.Name === 'CartValidation') {
          when(route, {
            templateUrl: `/Template/Cart/StepsCheckout/${route.Name.split('Cart')[1]}`,
            name: route.Name,
            cart: true,
            resolve: {
              /* @ngInject */
              authorize: authService => authService.authorize(true),
              /* @ngInject */
              load: ($q, $injector) => {
                const deferred = $q.defer();
                import(/* webpackChunkName: "cart.steps.validation" */ 'Modules/Cart/import/steps/validation.js').then(response => {
                  $injector.loadNewModules([response.default.name]);
                  deferred.resolve();
                });
                return deferred.promise;
              }
            },
            authorize: true,
            ngRoute: true
          });
        }
      } else if (route.Name === 'CartQuotationPaymentList') {
        when(route, {
          templateUrl: () => routingService.getLocation(),
          name: route.Name,
          cart: true,
          resolve: {
            /* @ngInject */
            load: ($q, $injector) => {
              const deferred = $q.defer();
              import(/* webpackChunkName: "cart.steps.payment" */ 'Modules/Cart/import/steps/payment.js').then(response => {
                $injector.loadNewModules([response.default.name]);
                deferred.resolve();
              });
              return deferred.promise;
            }
          },
          ngRoute: true
        });
      } else {
        when(route, {
          templateUrl: () => routingService.getLocation(),
          name: route.Name
        });
      }
    });

    if (window.isBot || window.isCache) {
      $locationProvider.html5Mode({
        enabled: true,
        requireBase: true,
        rewriteLinks: false
      });
      // désactivation du routing pour Google
      return;
    }

    $routeProvider.otherwise({
      templateUrl: () => routingService.getLocation()
    });

    $routeProvider.when('/404', {
      templateUrl: '/Template/Error/Error',
      name: '404'
    });

    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    });
  })

  /* @ngInject */
  .factory('RoutesService', () => {
    let routesService = {};
    let routesMap;
    return {
      set: value => {
        if (Object.getOwnPropertyNames(routesService).length > 0) return;
        routesService = value;
        routesMap = Object.entries(routesService).map(arr => ({
          name: arr[0],
          url: arr[1].url,
          route: arr[1].route
        }));
        return routesService;
      },
      get: () => routesService,
      getMap: () => routesMap,
      getUrlByName: name => (routesService[name] && routesService[name].url ? routesService[name].url : '')
    };
  })

  /* @ngInject */
  .factory('RoutingService', function ($rootScope, $timeout, $location, $window, $route) {
    let routing = {};
    const scrollCache = {};
    let cacheBusting = '';
    const service = {
      set,
      get,
      getLocation,
      getRoute,
      getPath,
      windowScroll,
      scrollCache,
      getCacheBusting,
      updateCacheBusting,
      forceReload
    };
    return service;

    ////////////
    function set(value) {
      routing = value;
    }

    function get() {
      return routing;
    }

    function getLocation() {
      return `${$location.path()}?t${cacheBusting}`;
    }

    function getCacheBusting() {
      return cacheBusting;
    }

    function updateCacheBusting() {
      cacheBusting = new Date().getTime();
    }

    function getRoute(url) {
      return `/${url.replace(/\:(\S+?)\}/g, '}').replace(/\{(\S+?)\}/g, ':$1')}`;
    }

    function getPath(url) {
      let path = url || $window.location.pathname;
      path = path.includes('http') && $window.location?.origin ? path.substr($window.location.origin.length).split('?')[0] : path;
      // Pour les articles, on récupère l'url du regroupement
      if (path.includes('-p-') && path.indexOf('-p-') > path.indexOf('-c-')) {
        const arr = path.split('-p-');
        path = `${arr[0]}-p-${arr[1].split('/')[0]}`;
      }
      return path;
    }

    function windowScroll(getScrollPos) {
      const top = !getScrollPos ? 0 : scrollCache[getPath()] || 0;
      $('html, body').scrollTop(top);
      $timeout(() => {
        $('html, body').scrollTop(top);
      }, 400);
    }

    function forceReload() {
      const currentRoute = routing.to;

      // Pour les routes hors Compte et Panier, on enregistre la position du scroll
      if (!currentRoute.name || (currentRoute.name && currentRoute.name.indexOf('Account') !== 0 && currentRoute.name.indexOf('Cart') !== 0)) {
        scrollCache[getPath(null)] = $window.pageYOffset;
      }

      updateCacheBusting();
      $rootScope.$broadcast('showPageLoader', true);
      $route.reload();
    }
  })

  /* @ngInject */
  .service('authService', function (AppService) {
    this.authorize = isCart =>
      AppService.getParams().then(params => {
        if (params.IsLogged && (!isCart || (isCart && params.HasCart))) return true;
        throw new Error(isCart && !params.HasCart ? 'noCart' : '');
      });
  })

  /* @ngInject */
  .factory('HttpRequestInterceptor', function ($q, $location, $injector, $rootScope) {
    return {
      response: res => {
        if (res.status === 210) {
          $rootScope.$broadcast('cartUpdateWithRefresh');
        }
        return res;
      },
      responseError: rejection => {
        if (rejection.status === 404 && rejection.config.headers['X-Requested-With'] !== 'jsonHttpRequest') {
          $injector.get('RoutingService').set({
            to: undefined,
            from: undefined
          });
          $location.path('/404/');
        } else if (rejection.status === 409) {
          // utilisé pour cart multi device
          window.location = window.location.href;
          $rootScope.$broadcast('cartUpdateWithRefresh');
        }
        return $q.reject(rejection);
      }
    };
  })

  /* @ngInject */
  .run((RoutesService, RoutingService) => {
    if (routes.Cart) {
      routes.Cart.step = 1;
      if (routes.CartIdentification) routes.CartIdentification.step = 2;
      routes.CartShipping.step = 3;
      routes.CartPayment.step = 4;
      routes.CartValidation.step = 5;
      if (routes.CartOnePageCheckout) routes.CartOnePageCheckout.step = 2;
    }
    RoutesService.set(routes);

    routingService = RoutingService;
  });
